<template>
    <div id="container">
        <!-- header -->
        <header class="header-container clearfix">
            <button
                class="back-btn"
                @click.prevent="$router.push('/home')"
            ></button>
            <h2>幸福轉盤</h2>
        </header>
        <section class="middle-container clearfix">
            <main class="main-container">
                <section class="content">
                    <div class="game-turntable">
                        <!-- transform 控制旋轉 -->
                        <ul :style="{ transform: rotate }">
                            <!-- <ul class="slow" style="transform: rotate(0deg);"> -->
                            <li
                                v-for="(prize, index) in prizes"
                                :class="'t' + (index + 1)"
                                :key="'prize' + index"
                            >
                                <p>
                                    {{ prize.rewardName.substring(0, 4) }}<br />
                                    {{ prize.rewardName.substring(4) }}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <button
                        class="start-btn"
                        @click.prevent="turn()"
                        :disabled="isDisabled"
                    >
                        開始
                    </button>
                </section>
            </main>
        </section>
        <Popup
            :open="popupObj.toPopup"
            :title="popupObj.title"
            :message="popupObj.message"
            :footer="popupObj.footer"
            @close="popupHandler"
        />
        <div class="body fixed mobile">
            <Spinner :spin="spin" />
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import Popup from "@/components/Popup";
import Spinner from "@/components/Spinner";

export default {
    components: {
        Popup,
        Spinner
    },
    rewardName: "Turntable",
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
       
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
                that.spin = true;
                that.getCampaigns()
                    .then(() => {
                        let campaign = that.campaigns.find(
                            (campaign) =>
                                campaign.campaignPrototype == "minigame"
                        );
                        console.log("campaign: ", campaign);
                        if (!(campaign && campaign.campaignId))
                            throw new Error("Error Campaign");
                        that.campaignId = campaign.campaignId;
                        that.gameStart();
                    })
                    .catch((err) => {
                        console.log("err: ", err);
                        this.$router.push("/error");
                    });
            } else {
                next("/error");
            }

            vm.$store.commit('setMuteNotifications', true);//NOTE: 讓這個頁面完全不會有notification alert
        });
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            campaignId: "",
            rotate: `rotate(0deg)`,
            deg: 0,
            speed: 5,
            targetDeg: 330,
            threadId: "",
            toStop: false,
            gameInfo: {
                prizes: [
                    {
                        rewardName: "紅利桃子5點",
                        weight: 2,
                        rewardId: 42
                    },
                    {
                        weight: 2,
                        rewardId: 41,
                        rewardName: "紅利桃子10點"
                    },
                    {
                        rewardId: 40,
                        rewardName: "紅利桃子15點",
                        weight: 2
                    },
                    {
                        rewardId: 39,
                        weight: 2,
                        rewardName: "紅利桃子20點"
                    }
                ],
                drawnPrize: {
                    rewardId: 39,
                    weight: 2,
                    rewardName: "紅利桃子20點"
                },
                submitUrl:
                    "http://localhost:3000/mod/luckydraw/events/SFY6Hw3lGTunwV5wZV5b/submit",
                ts: 1652437437294,
                hash: "53f5e4c6a566b34238a16653ec508c47456df652"
            },
            prizes: [],
            isTurned: false,
            popupObj: {
                toPopup: false,
                title: "",
                message: "",
                footer: false,
                buttonLeft: "",
                buttonRight: "",
                reset: function () {
                    this.toPopup = false;
                    this.tilte = "";
                    this.message = "";
                    this.footer = false;
                    this.buttonLeft = "";
                    this.buttonRight = "";
                }
            },
            spin: false,
            isDisabled: false
        };
    },
    created() {},
    computed: {
        ...mapState(["user"])
    },
    mounted() {
        // this.$store.commit('setMuteNotifications', true);
    },


    beforeRouteLeave (to, from, next) {
      this.$store.commit('setMuteNotifications', false);//NOTE:解除阻擋notification alert
      next();
    },

    methods: {
        showPopup() {
            this.popupObj.toPopup = true;
        },
        showPopupWitMessage(title = "", message = "") {
            console.log("show!!");
            this.popupObj.toPopup = true;
            this.popupObj.title = title;
            this.popupObj.message = message;
        },
        popupHandler() {
            this.popupObj.reset();
            this.$router.push("/home");
        },
        getCampaigns() {
            return (
                this.getCampaignAPI()
                    // this.campaignDummy()
                    .then((res) => {
                        this.campaigns = res.data.campaigns;
                        console.log("campaigns: ", this.campaigns);
                        return res;
                    })
            );
        },
        getCampaignAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true`,
                method: "GET"
            };
            return this.$http(config);
        },
        callGameInfoAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${this.campaignId}/run?uuid=${this.user.userId}`,
                method: "GET"
            };
            return this.$http(config);
        },
        dummyGameInfo() {
            let result = {
                prizes: [
                    {
                        rewardName: "紅利桃子5點",
                        weight: 2,
                        rewardId: 42
                    },
                    {
                        weight: 2,
                        rewardId: 41,
                        rewardName: "紅利桃子10點"
                    },
                    {
                        rewardId: 40,
                        rewardName: "紅利桃子15點",
                        weight: 2
                    },
                    {
                        rewardId: 39,
                        weight: 2,
                        rewardName: "紅利桃子20點"
                    }
                ],
                drawnPrize: {
                    rewardId: 39,
                    weight: 2,
                    rewardName: "紅利桃子20點"
                },
                submitUrl:
                    "http://localhost:3000/mod/luckydraw/events/SFY6Hw3lGTunwV5wZV5b/submit",
                ts: 1652437437294,
                hash: "53f5e4c6a566b34238a16653ec508c47456df652"
            };
            let promise = new Promise((resolve) => {
                resolve({ data: result });
            });
            return promise;
        },
        gameStart() {
            this.callGameInfoAPI()
                // this.dummyGameInfo()
                .then((gameInfoRes) => {
                    this.gameInfo = JSON.parse(
                        JSON.stringify(gameInfoRes.data)
                    );
                    console.log("gameInfo: ", this.gameInfo);
                    this.expendPrizes();
                    this.calTargetDeg();
                    this.spin = false;
                })
                .catch((gameInfoErr) => {
                    console.log("gameInfoErr: ", gameInfoErr);
                    this.spin = false;
                    let title = "今日已無法參加抽獎";
                    this.showPopupWitMessage(title);
                    this.isDisabled = false;
                });
        },
        draw() {
            let data = JSON.stringify({
                uuid: this.user.userId,
                ts: this.gameInfo.ts,
                hash: this.gameInfo.hash
            });
            let config = {
                url: this.gameInfo.submitUrl,
                method: "POST",
                data: data
            };
            return this.$http(config);
        },
        expendPrizes() {
            let originPrizes = this.gameInfo.prizes;
            if(originPrizes.length == 8) {
                console.log(this.prizes);
            } else if(originPrizes.length == 4) {
                originPrizes.forEach((e) => {
                    this.prizes.push(e);
                });
                originPrizes.forEach((e) => {
                    this.prizes.push(e);
                });
                console.log(this.prizes);
            } else if (originPrizes.length == 2) {
                originPrizes.forEach((e) => {
                    this.prizes.push(e);
                });
                originPrizes.forEach((e) => {
                    this.prizes.push(e);
                });
                originPrizes.forEach((e) => {
                    this.prizes.push(e);
                });
                originPrizes.forEach((e) => {
                    this.prizes.push(e);
                });
                console.log(this.prizes);
            } else {
                throw new Error(`Error prize setting.`);
            }
        },
        toRotate(deg) {
            return `rotate(${deg}deg)`;
        },
        animationInit() {
            this.toStop = false;
            this.speed = 5;
        },
        turn() {

            this.isDisabled = true;
            if (this.isTurned == false) {
                this.draw()
                    .then(() => {
                        this.isTurned = true;
                        this.animationInit();
                        this.threadId = setInterval(() => {
                            this.deg = (this.deg + this.speed) % 360;
                            this.rotate = this.toRotate(this.deg);
                            if (
                                this.toStop &&
                                this.deg >= this.targetDeg - this.speed &&
                                this.deg <= this.targetDeg + this.speed
                            ) {
                                clearInterval(this.threadId);
                                let title = `您獲得了`;
                                let message = `${this.displayPrize()}`;
                                this.showPopupWitMessage(title, message);
                            }
                        }, 1);
                        this.slow();
                        this.stop();
                    })
                    .catch((err) => {
                        console.log("err: ", err);
                        let title = "今日已無法參加抽獎";
                        this.showPopupWitMessage(title);
                        this.isDisabled = false;
                    });
            } else {
                let title = `您已經參加過小遊戲了`;
                this.showPopupWitMessage(title);
                this.isDisabled = false;
            }
        },
        slow() {
            setTimeout(() => {
                this.speed = 4.2;
            }, 500);
            setTimeout(() => {
                this.speed = 3.4;
            }, 1000);
            setTimeout(() => {
                this.speed = 2.6;
            }, 1500);
            setTimeout(() => {
                this.speed = 1.8;
            }, 2000);
            setTimeout(() => {
                this.speed = 1;
            }, 2500);
        },
        stop() {
            setTimeout(() => {
                this.toStop = true;
            }, 2800);
        },
        calTargetDeg() {
            let unitDeg = 360 / 8;
            let prizeIndex = this.prizes.findIndex(
                (prize) => prize.rewardId == this.gameInfo.drawnPrize.rewardId
            );
            if (prizeIndex != -1) {
                this.targetDeg = 360 - prizeIndex * unitDeg - 5;
            } else {
                this.$router.push("/error");
            }
        },
        displayPrize() {
            let prize = this.prizes.find(
                (prize) => prize.rewardId == this.gameInfo.drawnPrize.rewardId
            );
            return prize.rewardName;
        }
    }
};
</script>

<style scoped src="@/assets/css/turntable.css"></style>
